<template>
	<!--pre>{{project_id}} {{this.$store.state.allEmlInProgr}} {{this.$store.state.allEmlInProgr.indexOf(project_id)}} {{this.$store.state.allEmlInProgr.indexOf(project_id) > -1}}</pre-->
	<button 
		v-if="lead.email_list.length == 0"  
		:class="{'button':true, 'is-loading':emlClicked || this.$store.state.allEmlInProgr.indexOf(project.id) > -1}" 
		@click="emailSaas"
	>
			&#9993; Get Emails 
			<span class="credits1">8</span>
	</button>
	<div v-else>
		<strong>Emails:</strong><br>
		<!-- <div v-html="html1"></div> -->
		<div v-for="eml in lead.email_list">
			<!-- <span @click="emailClick(eml.addr)" :class="{is_addr: is_addr}">{{eml.addr}}</span> -->
			<div v-if="eml.addr === 'Not found'">
				Not found 
				<span class="eml2"> (as of {{eml.upd_date.toString().split("T")[0]}})</span><br>
			</div>
			<div v-else>
				<!--span class="is_addr" @click="$emit('email-click', eml.addr)"-->
				<span class="is_addr" @click="emailCreate(eml.addr)">
					{{eml.addr}}
				</span>
				<span class="eml2"> (as of {{eml.upd_date.toString().split("T")[0]}})</span> &nbsp; 
				<!--LeadLlmMessage :lead="lead" :project="project" :eml_addr="eml.addr" /-->
			</div>
		</div>
	</div>	
</template>

<script>
    import axios from 'axios'
    //import LeadLlmMessage from '@/components/services/LeadLlmMessage'

    export default {
    	name: 'EmailSaas',
    	components: {}, //LeadLlmMessage},

    	props: {
    		lead: {},
    		project: {},
    		//mail_list_status: 0,
    	},
    	emits: ['email-click'],
    	data() {
    		return {
    			html1: '',
    			email_list: [
    				{
    					addr: '',
    					upd_date: ''
    				}
    			],
    			is_addr: false,
    			emlClicked: false,
    			credits_out: false,

    		}
    	},

    	methods:{
		    async emailSaas() {
		        //this.$store.commit('setIsLoading', true)
		        //console.log("EmailSaas lead:", this.lead)
		        this.emlClicked = true

		        if (!this.lead.name) {
		        	this.lead.name = this.lead.contact_person
		        }
		        //console.log("EmailSaas lead:", this.lead)
		        
		        await axios
		            .get(`/lms/email/find/${this.lead.id}`)
		            .then(response => {
		            	this.$store.state.teamCredits = response.data.team_credits
		            	if (response.data.email_list != 'Not enough credits') 
		            	{
		                	this.lead.email_list = response.data.email_list
		                	//console.log("this.lead.email from saas", this.lead.email_list)
			                if (this.lead.email_list[0].addr != 'Not found') {
								this.$store.state.isProjLeadsEmail = true
			                }
			            } else {
			            	this.$store.state.creditsOut = true
			            }
		            })
		         	.catch(error => {
                        console.log(error)
                    })
		        this.emlClicked = false
		        //this.$store.commit('setIsLoading', false)
		    },
		    emailCreate(addrclick) {
                let m_subj = '  '
                let m_body = ''
                console.log('llm=', this.lead)
                if (this.lead.llm_message) {
                	//m_body = encodeURIComponent(this.lead.llm_message)
                	m_subj = encodeURI(this.lead.llm_subject)
                	m_body = encodeURI(this.lead.llm_message)
                } else {
                	m_body = encodeURI(`Dear ${this.lead.contact_person},\n\n`)
                	m_body += this.project ? encodeURI(this.project.msg_body) : ''
            	}
            	location.href = `mailto:${addrclick}?subject=${m_subj}&body=${m_body}`
            },
		}
	}
</script>

<style lang="scss">
	.eml2 {
		font-size: smaller;
		color: #999;
	}
	.is_addr {
		cursor: pointer;
		color: #3273dc;
		&:hover {
			color: #006;
		}
	}
</style>