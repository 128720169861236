<template>
	<p v-if="enrich_sh">&nbsp;</p>
	<button v-if="enrLinks" style="min-width: 150px;" class="button" @click="enrich_sh = !enrich_sh">
		<span v-html="btn.icon"></span>&nbsp;{{btn.name}}&nbsp;({{enrLinks.length}})&nbsp;&nbsp;{{enrich_sh ? '&#9650;' : '&#9660;'}}
	</button>
	<div class="pf_more_links" v-if="enrich_sh">
		<div class="box" v-if="enrLinks.length === 0">
			No enriched info found
		</div>
		<div v-for="(link, idx) in enrLinks" class="box1 erchbx">
		<!--div v-for="(link, idx) in enrLinks" class="box1 erchbx"-->
			<button class="button1" @click="removeLink(idx, item.id)">&#9746;&nbsp;delete</button>
			<a :href="link.url" target="blank" class="rlink">{{link.url}}</a>
			<div><b>{{link.title}}</b></div>
			<div>{{link.snippet}}</div>
		</div>
	</div>
	<div v-else-if="is_progress == 1" class="progdiv">
		Background Enrichment process can take up to one minute. You may continue working on Project.
		<div class="progress-bar progress-bar-striped bg-info" role="progressbar" :style="`width: ${prog}%;`">
			&nbsp;
		</div>
	</div>
	<div v-else-if="is_progress == -1" class="progdiv">
		No Enriched data found with this attempt. We can use another enrichment approach - please <button @click="enrToLead">retry to Enrich</button>
	</div>

	<span v-if="action != 'company' || item.company">
		<button v-if="!enrLinks && !is_progress" class="button" @click="enrToLead(btn)">
			<span v-html="btn.icon"></span>&nbsp;{{btn.name}}  <span class="credits1">{{btn.price}}</span>
		</button>
	</span>
	
</template>

<script>
    import axios from 'axios'

    export default {
    	name: 'LeadEnrichButtons',
    	props: {
    		item: {},
    		action: ''
    	},
    	data(){return{
    		is_progress: 0,
    		prog: 0,
		    progress: null,
		    enrich_sh: false,
		    enrich_data: {},
		    actn: {
		    	'social': {
			    	name: 'Social',
			    	price: 8,		  
			    	icon: '&#127917;',

		    	},
		    	'web': {
			    	name: 'Web',
			    	price: 8,
			    	icon: '&#x1F310;',
		    	},
		    	'company': {
			    	name: 'Company',
			    	price: 8,
			    	icon: '&#128181;',
		    	},
		    },
		    btn: {},
		    resp_res: {}


    	}},
    	mounted() {
    		this.btn = this.actn[this.action]
    		//this.enrFromLead()
    	},

    	computed: {
    		enrLinks() {
    			// console.log(this.item.contact_person, '/ computed > ',  this.action)
    		    if (this.action === 'web') {
    				if(this.item.enrich) {
    					//console.log('item.enrich', this.item.enrich)
    			    	return this.item.enrich.links
    			    }
    			} else if (this.action === 'social') {
    				if(this.item.enr_social) {
    					//console.log('social data', this.item.enr_social)
    					return this.item.enr_social.links
    			    }
    			} else if (this.action == 'company') {
    				if(this.item.enr_company !== null) {
    					//console.log('item.enr_company', this.item.enr_company)
    			    	return this.item.enr_company.links
    			    }
    			}
    			return false
    		}
    	},

    	methods:{
    		/*
    		enrFromLead() {
    			// Enrichment data from DB if exists

    			if (this.action === 'web') {
    				if(this.item.enrich) {
    			    	this.resp_res = this.item.enrich.links
    			    	console.log(this.resp_res)
    			    	return true
    			    }
    			} else if (this.action === 'social') {
    				if(this.item.enr_social) {
    					//console.log('social data', item.enr_social)
    					this.resp_res = this.item.enr_social.links
    					console.log(this.resp_res)
    					return true
    			    }
    			} else if (this.action === 'company') {
    				//console.log('item.enr_company', this.item.enr_company)
    				if(this.item.enr_company !== null) {
    			    	this.resp_res = this.item.enr_company.links
    			    	console.log(this.resp_res)
    			    	return true
    			    }
    			}

    			this.resp_res = null
    			return false
    		},
			*/
		    async enrToLead(btn) {
		    	// Enrichment data fetching from the web if not exists in DB
		    	this.proGrow()
		        await axios
		            .post('/lms/lead/enrich/', {lead_id:this.item.id, act:this.action})
		            .then(response => {
		            	const rdr = response.data.result
		            	// console.log('enrToLead rdr', rdr)
		            	this.$store.state.teamCredits = response.data.team_credits

		            	if(!response.data.credit_status) {
							this.$store.state.creditsOut = true
		            	} else {
			    			if (this.action == 'web') {
			    			    this.item.enrich = {'links': rdr.links}
			    			} else if (this.action == 'social') {
			    				this.item.enr_social = {'links': rdr.links}
			    			} else if (this.action == 'company') {
			    			    this.item.enr_company = {'links': rdr.links}
			    			}
			    			//this.resp_res = rdr.links
			                this.enrich_sh = true
		            	}
		            	
		            })
		            .then(this.proReset)
		            .catch(error => {
		            	this.is_progress = -1
		            	clearInterval(this.progress)
                        console.log('!err', error)
                    })
		        //this.is_progress = 0
		    },
		    proGrow() {
		    	this.is_progress = 1
		    	// https://codepen.io/roytanaka/pen/vYBgXEX
				if (this.progress === null) {
				this.progress = setInterval(() => {
					if (this.prog === 100) {
						clearInterval(this.progress)
					} else {
						this.rand = Math.floor(Math.random() * 8)
						this.prog += this.rand;
						if (this.prog > 99) {
						  this.prog = 100
						  // this.is_progress = -1
						}
					}
				}, 1000);
		      }
		    },
		    proReset() {
				clearInterval(this.progress)
				this.progress = null
				this.prog = 0
				this.is_progress = 0
		    },
		    async removeLink(idx, lead_id) {
                this.$store.commit('setIsLoading', true)
    			if (this.action == 'web') {
    				this.item.enrich.links.splice(idx, 1)
    			} else if (this.action == 'social') {
    				this.item.enr_social.links.splice(idx, 1)
    			} else if (this.action == 'company') {
    				this.item.enr_company.links.splice(idx, 1)
    			}
        	
                await axios
                    .patch(`/lms/leads/${lead_id}/`, this.item)
                    .then(response => {
                    	console.log('remove from lead:', lead_id)                       
                    })
                    .catch(error => {
                        console.log(error)
                    })
			    this.$store.commit('setIsLoading', false)
            },
		}
	}
</script>
<style>
	.erchbx {
		font-size: small;

	}
	.box1 {
		border-bottom: 1px dotted #999;
		padding: 0 1vw;
		background-color: #fcfcfc;
		border-radius: 4px;
		margin:2px 0;

	}
	.button1 {
		float: right;
		color: #900;
		cursor: pointer;
		border: 0;

	}
	.pf_more_links{
		margin: 16px 0;
	}
	.rlink {
		white-space: nowrap;
		 display:flex;
  		overflow: hidden;
	}

	.progress-bar {
	    /* display: flex; */
	    /* display: inline-block; */ /*  */
	    /* width: 240px; */
	    /* height: 2rem; */
	    /* overflow: hidden; */
	    /* font-size: 1rem; */
	    /* background-color: #e9ecef; */
	    /* border-radius: .25rem; */
	    /* margin-bottom: 0 !important; */ /*  */
	}
	.bg-info {
    	background-color: #17a2b8 !important;

	}
	.progress-bar-striped {
	    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	    /* background-size: 1rem 1rem;  */

	}

	.progdiv {
	    background-color: white;
	    border: 1px solid #dbdbdb;
	    border-radius: 4px;

	    color: #363636;	    
	    padding-bottom: calc(0.5em - 1px);
	    padding-top: calc(0.5em - 1px);

	    padding-left: 1em;
	    padding-right: 1em;
    	
	}
</style>
