<template>
	<button class="button" @click="saveCsvCheck()">
		<u>&#8681;</u>&nbsp;CSV
		<span v-if="!project.mail_list_status" class="credits1">{{PRICE}}</span>
	</button>
</template>

<script>
	import axios from 'axios'

	export default {
        name: 'SaveCsv',
        data() {
            return {
                csvFN: '',
                PRICE: 40
            }
        },
        props: {
        	leads: [],
        	project: {},
        },
        
        methods: {      	
        	async saveCsvCheck() {
        		this.$store.commit('setIsLoading', true)
        		 
        		console.log("Save Csv this.project.mail_list_status", this.project.mail_list_status)
        		// credits needed -> check team.credits
        		if (this.project.mail_list_status !== 1 ) {
	        		// check credits and pay credits at the backend first
	        		const checkCredits = await axios
	                    .post('/lms/project/save-csv/', {'project_id': this.project.id})
	                //console.log('checkCredits', checkCredits.data.team_credits)
	                this.$store.state.teamCredits = checkCredits.data.team_credits

	                // not enough team.credits -> reject
			        if (!checkCredits.data.csv_downl) {
	                   	this.$store.state.creditsOut = true
	                   	this.$store.commit('setIsLoading', false)
	                   	return {'error': 'Not enough credits'}
	                } else {
	                // enough team.credits -> go
	                	this.saveCsv()
	                }
	            // no credits needed -> go
	            } else {
	            	this.saveCsv()
	            }
	            this.$store.commit('setIsLoading', false)
	        },
	        async saveCsv() {	

	        		let csvFN = `b2bsearch_${this.project.id}_${this.project.title
	        			//prettify file name
	        			.replace(", loc: ","_")
	        			.replace("LI: ","")
	        			.replace(" [","[")
	        			.replaceAll(" - ","-")
	        			.replaceAll(" ","-")
	        			}.csv`
					console.log('save-csv FN:', csvFN)

					let csvContent = 'NAME1\tNAME2\tCOMPANY\tJOB\tLINKEDIN\tEMAIL\tBREIF\n'

					//let persons = this.leads 

					//console.log('save-csv persons:', persons)
					
					this.leads.forEach((row,ind) => {
						//console.log('row.email_list:', row.email_list)
						let emails = ''
						row.email_list.forEach((eml,idx) => {
							//console.log('row > eml:', eml.addr)
							emails += `${eml.addr},`
							})
						emails = emails.slice(0, -1)
						csvContent += `${row.contact_person.replace(' ','\t')}\t${row.company}\t${row.job}\t${row.linkedin}\t${emails}\t${row.snippet}\n`
						
					})

					//console.log("csvContent", csvContent)

					let link = document.createElement('a')
					link.id = "dwnld"+this.project.id
					link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csvContent));
					link.setAttribute('download', csvFN);
					console.log("link", link)
					document.body.appendChild(link)
					document.querySelector("#dwnld"+this.project.id).click()
					//document.querySelector(`#${this.results.id}`).remove()
				
        		
        	}

        },
    }
</script>