<template>
    <div v-if="project.mail_list_status === 9" class="emlprogress emlloading emlbgwarn">
        Finding emails.. 
    </div>
    <div v-if="finishFlag" class="emlprogress emlbgsucc" @click="closeEmlSucc">
        Finding emails completed
        <span class="closeEmlSucc">&times;</span>&nbsp;
    </div>
    <!--button v-if="!project.mail_list_status" class="button" @click="confirm" -->
    <button v-if="emailCount.notenriched !== 0" :class="{'button':true, 'is-loading':this.$store.state.allEmlInProgr.indexOf(project.id) > -1}" @click="confirm">
        &#8694;&nbsp;Get All Emails, max  {{emailCount.notenriched}} <span class="credits1">max {{predPrice}}</span>
    </button>

    <div class="notification is-danger" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>

    <div class="modal" :class="{'is-active': showModal}">
        <div class="modal-background"></div>
            <div class="modal-card">

            <footer class="modal-card-head">
                <h2 class="title">Confirm</h2>
            </footer>

            <section class="modal-card-body"> 
                <p v-if="paySchema === 1">Finding all emails will cost a maximum of {{predPrice}} credits for all available emails.</p>

                <p v-if="paySchema === 2">Finding all emails requires a maximum of {{predPrice}} credits. Your current balance is {{$store.state.teamCredits}} credits, but you can proceed to find as many emails as your current balance allows.</p>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-success" @click="launch">Proceed</button>
                <button class="button" @click="cancelconfirm">Cancel</button>
            </footer>
        </div>
    </div>
</template>
<script>
	import axios from 'axios'
	export default {
		name: 'EmailAll',
		data () { return {
			errors: [],
            paySchema: 0,
            predPrice: 0,
            showModal: false,
            apiCall: {},
            unit_price: 3,
            timerId: 0,
            finishFlag: false

		}},
        props: {
            project: {},
            emailCount: {
                found: 0,
                notfound: 0,
                notenriched: 0
            },
            leads: []
        },
        emits: ['mlist-finish'],
        updated() {
            this.predPrice = Math.round(this.emailCount.notenriched * 8 * 0.8)
        },
        mounted() {
            this.predPrice = Math.round(this.emailCount.notenriched * 8 * 0.8)
            if(this.project.mail_list_status === 9) {
                this.mlistStatusTimer(project_id)
            }
        },
		methods: {
            mlistStatusTimer(project_id) {
                this.timerId = setInterval(() => this.mailListCheck(project_id), 5000)
                console.log('Set interval timerId', this.timerId)
            },
			async confirm() {
                //console.log('EmailAll project_id', project_id)
                //console.log('searchProgress project', this.project)

                await axios
                    .get('lms/teams/credits/')
                    .then(response => {
                        const teamCredits = response.data.team_credits
                        console.log('response teamCredits', teamCredits)
                        this.$store.state.teamCredits = teamCredits

                        if (teamCredits < this.unit_price) {
                            this.$store.state.creditsOut = true
                        } else {
                            if (this.predPrice < teamCredits) {
                                this.paySchema = 1
                            } else {
                                this.paySchema = 2
                            }
                            this.showModal = true
                        }
                    })
                    .catch(error => {
                        this.$store.commit('setIsLoading', false)
                        console.log(error)
                        if (error.response) {
                            this.errors.push(`Err: ${error.response.status}`)
                        } else if (error.message) {
                            this.errors.push(`Err: ${error.message}`)
                        }
                    })
            },
            async launch() { 
                const project_id = window.location.pathname.split('/')[2]
                this.showModal = false
                this.project.mail_list_status = 9
                
                let allEmlInProgrVal = this.$store.state.allEmlInProgr
                console.log('EmailAll line 114 / allEmlInProgrVal', allEmlInProgrVal)
                allEmlInProgrVal.push(+project_id)
                this.$store.state.allEmlInProgr = allEmlInProgrVal
                console.log('EmailAll line 118 / $store.state.allEmlInProgr', this.$store.state.allEmlInProgr)
                if(this.paySchema === 1) {
                    this.$store.state.teamCredits -= this.predPrice
                }
                // remove filteredArr = ary.filter(v => v !== 'seven')
                const requestData = {
                    'project_id': project_id,
                    'pred_price': this.predPrice,
                    'pay_schema': this.paySchema
                }

                await axios
                    .post(`lms/project/mail-list/`, requestData)
                    .then(response => {
                        console.log('launch', response.data)
                        this.$store.state.teamCredits = response.data.team_credits
                        if (response.data.team_credits < this.unit_price) {
                            this.$store.state.creditsOut = true
                        } else {
                            this.project.mail_list_status = 9
                            this.mlistStatusTimer(project_id)
                        }

                    })
                    .catch(error => {
                        this.project.mail_list_status = 5
                    	this.$store.commit('setIsLoading', false)
                    	console.log(error)
                        if (error.response) {
                            this.errors.push(`Err: ${error.response.status}`)
                        } else if (error.message) {
                            this.errors.push(`Err: ${error.message}`)
                        }
                    })
            },
            mailListCheck(project_id) {
                //console.log('mailListCheck in progress')
                
                axios
                    .post(`/lms/project/mail-list-check`, {'project_id': project_id})
                    .then(response => {
                        console.log('mailListCheck response', response.data)
                        //this.project = response.data.project
                        //this.leads = response.data.leads
                        this.project.mail_list_status = response.data.project_status
                        console.log("mailListCheck project status", this.project.mail_list_status)
                        if (this.project.mail_list_status !== 9){
                            console.log('clearInterval timerId', this.timerId)
                            clearInterval(this.timerId)
                            this.finishFlag = true
                            this.$emit('mlist-finish')
                            let aeip = this.$store.state.allEmlInProgr 
                            this.$store.state.allEmlInProgr = aeip.filter(v => v !== +project_id)
                            
                        }
                
                    })
                    .catch(error => {
                        this.$store.commit('setIsLoading', false)
                        console.log(error)
                        if (error.response) {
                            this.errors.push(`err ${error.response.status}`)
                        } else if (error.message) {
                            this.errors.push(`Err: ${error.message}`)
                        }
                    })
            },
            cancelconfirm() {
                this.showModal = false
            },
            closeEmlSucc() {
                this.finishFlag = false
            }

		}
	}
</script>
<style>
    .closeEmlSucc{
        display:inline-block;
        margin-top: -20px;
        margin-left:-4px;
        position: inherit;
        width:40px;
        height: 40px;
        cursor: pointer;
        color: #090;

    }
    .emlprogress {
        position: fixed;
        z-index: 9999;
        top: 10px;
        left: 20px;
        padding: 10px;
        font-size: larger;
        font-weight: 600;
        border-radius: 8px;
    }
    .emlbgwarn {
        background-color: #FFDD57;
    }
    .emlbgsucc {
      background-color: #00D1B2;
      cursor: pointer;
    }
    .emlloading {
        &:before{
            content: url(/public/loading1.gif);
        }
    }
</style>